import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { CONFIRM_PAYMENT } from '../redux/reducers/payment';

export async function confirmPayment(values, store = {}) {
  const { data } = await apiCall({
    method: 'post',
    url: '/payment/ooredoo/confirm',
    data: values,
  });

  if (data?.status === 200) {
    store.dispatch({
      type: CONFIRM_PAYMENT,
      payload: {
        item: data?.data,
      },
    });
  } else {
    const message = data?.message ? data?.message : 'error occured';
    toast.showError(store, message);
  }
  return data;
}
