import { Typography, styled } from '@mui/material';

const CQtypographyRed = styled(Typography)(({ theme }) => ({
  fontSize: theme.shape.MainSize,
  color: theme.palette.CustomRed.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.shape.MainSize,
  },
  cursor: 'pointer',
}));

export default CQtypographyRed;
