import { createSlice } from '@reduxjs/toolkit';

export const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    list: [],
  },
  reducers: {
    getList: (state, action) => {
      const { data } = action.payload;
      state.list = data;
    },
    update: (state, action) => {
      const { data, index } = action.payload;
      state.list[index] = data;
    },
  },
});

export const GET_LIST_OFFER = 'offer/getList';
export const UPDATE_OFFER = 'offer/update';

export default offerSlice.reducer;
