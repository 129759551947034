import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    addPayment: false,
    confirmPayment: false,
    activatePayment: false,
  },
  reducers: {
    add: (state, action) => {
      const { status } = action.payload;
      state.addPayment = status;
    },
    confirm: (state, action) => {
      const { status } = action.payload;
      state.confirmPayment = status;
    },
    activate: (state, action) => {
      const { status } = action.payload;
      state.activatePayment = status;
    },
  },
});

export const ADD_PAYMENT = 'payment/add';
export const CONFIRM_PAYMENT = 'payment/confirm';
export const ACTIVATE_PAYMENT = 'payment/activate';

export default paymentSlice.reducer;
