import React, { useRef } from "react";
import { Field } from "formik";
import { Stack, IconButton, styled } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const CostumIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
    size: "small",
  },
}));

const CQinputField = ({ fieldName, calTotal }) => {
  const inputNumber = useRef();

  return (
    <Stack direction="row">
      <Field name={fieldName}>
        {({ field, form }) => {
          const { setFieldValue, handleBlur, handleChange } = form;
          return (
            <>
              <CostumIconButton
                onClick={() => {
                  inputNumber.current.stepUp();
                  setFieldValue(`${fieldName}`, inputNumber.current.value);
                  calTotal(inputNumber.current.value);
                }}
              >
                <AddCircleIcon htmlColor="#0f75a0" fontSize="inherit" />
              </CostumIconButton>
              <input
                type="number"
                className="input-number"
                name={fieldName}
                onBlur={() => handleBlur()}
                readOnly
                ref={inputNumber}
                onChange={() => handleChange()}
                min={1}
                max={10}
                {...field}
              />
              <CostumIconButton
                onClick={() => {
                  inputNumber.current.stepDown();
                  setFieldValue(`${fieldName}`, inputNumber.current.value);
                  calTotal(inputNumber.current.value);
                }}
              >
                <RemoveCircleIcon htmlColor="#0f75a0" fontSize="inherit" />
              </CostumIconButton>
            </>
          );
        }}
      </Field>
    </Stack>
  );
};

export default CQinputField;
