import { Button, styled } from "@mui/material";

const CQbuttonBlue = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusPill,
  backgroundImage: `linear-gradient(to bottom,${theme.palette.CustomBlue.light},${theme.palette.CustomBlue.dark})`,
  fontSize: "18px",
  color: theme.palette.common.white,
  width: "100%",
  padding: "0px",
  marginTop: "10px",
}));

export default CQbuttonBlue;
