import { createTheme } from "@mui/material";
const theme = createTheme({
  palette: {
    CustomBlue: {
      main: "#0f75a0",
      dark: "#0b7bb6",
      light: "#52b9e9",
    },
    CustomRed: {
      main: "#ee2e3c",
      dark: "#CB1212",
      light: "#F93535",
    },
    CustomOrange: {
      main: "#f6c624",
      dark: "#ffe795",
    },
    CustomGreen: {
      main: "#CBEB0F",
      dark: "#95c11f",
    },
  },
  typography: {
    fontFamily: "Ara Jozoor Regular",
  },
  shape: {
    MainSize: "1.875rem",
    borderRadiusPill: "50rem",
    borderRadiusCircle: "50%",
  },
});
export default theme;
