import { createSlice } from '@reduxjs/toolkit';

export const studentSlice = createSlice({
  name: 'student',
  initialState: {
    list: [],
  },
  reducers: {
    getList: (state, action) => {
      const { data } = action.payload;
      state.list = data;
    },
    add: (state, action) => {
      const { data } = action.payload;
      state.list.unshift(data);
    },
    update: (state, action) => {
      const { data, index } = action.payload;
      state.list[index] = data;
    },
  },
});

export const GET_LIST_STUDENT = 'student/getList';
export const ADD_STUDENT = 'student/add';
export const UPDATE_STUDENT = 'student/update';

export default studentSlice.reducer;
