import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    connected: false,
    emailExist: null,
    userProfile: {},
    token: null,
    children: 0,
  },
  reducers: {
    addDataConnection: (state, action) => {
      const { connected, userProfile, token, children } = action.payload;
      state.connected = connected;
      state.userProfile = userProfile;
      state.token = token;
      state.children = children;
    },
    getProfile: (state, action) => {
      const { userProfile } = action.payload;
      state.connected = true;
      state.userProfile = userProfile;
    },
    reset: (state, action) => {
      return (state = {
        connected: false,
        userProfile: {},
        token: null,
      });
    },
    editProfile: (dataSet, action) => {
      const { userProfile } = action.payload;
      dataSet['userProfile'] = {
        ...userProfile,
      };
    },
  },
});

export const ADD_CONNECTION_PROPS = 'auth/addDataConnection';
export const GET_ME = 'auth/getProfile';
export const RESET_SETTINGS = 'auth/reset';
export const EDIT_PROFILE = 'auth/editProfile';
export const UPLOAD_IMAGE_PROFILE = 'auth/uploadImage';
export const UPDATE_ID_FIRST_CONNECTION = 'auth/updateIdFirstConnection';

export default authSlice.reducer;
