import { Typography, styled } from "@mui/material";

const CQtypographyBlue = styled(Typography)(({ theme }) => ({
  fontSize: theme.shape.MainSize,
  color: theme.palette.CustomBlue.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.shape.MainSize,
  },
}));

export default CQtypographyBlue;
