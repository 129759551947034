import { createSlice } from '@reduxjs/toolkit';

export const levelSlice = createSlice({
  name: 'level',
  initialState: {
    list: [],
  },
  reducers: {
    getList: (state, action) => {
      const { data } = action.payload;
      state.list = data;
    },
  },
});

export const GET_LIST_LEVEL = 'level/getList';

export default levelSlice.reducer;
