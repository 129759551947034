import React from 'react';
import { Box, Typography } from '@mui/material';
import './CQpersonCard.css';

const CQpersonCard = ({ children, isOfferActivate = true, key }) => {
  if (isOfferActivate) {
    return (
      <div key={key} className="person-card-border">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            className="person-image"
            alt={children.fullName}
            src={children?.avatar?.urlPath}
          />
        </Box>
        <Box>
          <Box className="person-name">
            <Typography
              fontSize="1.446rem"
              component="p"
              color="CustomBlue.light"
            >
              {children.fullName}
            </Typography>
            <Typography
              fontSize="1.446rem"
              component="p"
              color="CustomBlue.light"
            >
              {children.level.name}
            </Typography>
          </Box>
        </Box>
      </div>
    );
  }
};

export default CQpersonCard;
