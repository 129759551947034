import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

import authReducer from './reducers/auth';
import paymentReducer from './reducers/payment';
import offerReducer from './reducers/offer';
import levelReducer from './reducers/level';
import studentReducer from './reducers/student';
import toastReducer from './reducers/toast';
import loaderReducer from './reducers/loader';

const history = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(history)];

export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    auth: authReducer,
    payment: paymentReducer,
    offer: offerReducer,
    level: levelReducer,
    student: studentReducer,
    toast: toastReducer,
    router: routerReducer,
  },
  middlewares,
});
