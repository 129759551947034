import { Typography, styled } from "@mui/material";

const CQtypographyPrice = styled(Typography)(({ theme }) => ({
  fontSize: theme.shape.MainSize,
  color: theme.palette.CustomBlue.dark,
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.shape.MainSize,
  },
}));

export default CQtypographyPrice;
