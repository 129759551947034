export const classQuizWebAppUrl = process.env.REACT_APP_CLASSQUIZ_WEB_APP;

const classQuizDeepLinkSchema = process.env.REACT_APP_ANDROID_DEEPLINK_SCHEME;
const huaweiDeepLinkSchema = process.env.REACT_APP_HUAWEI_DEEPLINK_SCHEME;
const classquizAppGalleryUrl = process.env.REACT_APP_CLASSUIZ_APP_GALLERY_URL;
const classquizIosPage = process.env.REACT_APP_CLASSUIZ_IOS_PAGE;

const classQuizDeepLink = `${classQuizDeepLinkSchema}`;
const classquizIosPageUrl = `${classquizIosPage}`;
const huaweiDeepLink = `${huaweiDeepLinkSchema}`;
const appGalleryUrl = `${classquizAppGalleryUrl}`;

export { classQuizDeepLink, huaweiDeepLink, appGalleryUrl, classquizIosPageUrl };
