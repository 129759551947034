import { Button, styled } from "@mui/material";

const CQbuttonGreen = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusPill,
  backgroundImage: `linear-gradient(to bottom,${theme.palette.CustomGreen.main},${theme.palette.CustomGreen.dark})`,
  fontSize: theme.shape.MainSize + " " + "!important",
  minWidth: "11rem",
  color: theme.palette.common.white,
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px !important",
    minWidth: "8rem",
  },
}));

export default CQbuttonGreen;
