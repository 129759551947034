import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { ADD_PAYMENT } from '../redux/reducers/payment';

export async function createPayment(values, store = {}) {
  const { data } = await apiCall({
    method: 'post',
    url: '/payment/ooredoo/web/purchase',
    data: values,
  });

  if (data?.status) {
    store.dispatch({
      type: ADD_PAYMENT,
      payload: {
        item: data?.data,
      },
    });

    toast.showSuccess(store, data?.message);
  } else {
    const message = data?.message ? data?.message : 'error occured';
    toast.showError(store, message);
  }
  return data;
}
