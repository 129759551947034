// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/backgrounds/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  padding-top: 3.125rem;\n  padding-bottom: 3.125rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layout/layout.css"],"names":[],"mappings":"AAAA;EACE,yDAA0E;EAC1E,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;EACtB,qBAAqB;EACrB,wBAAwB;AAC1B","sourcesContent":[".layout {\n  background-image: url(\"../../../public/images/backgrounds/background.png\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  padding-top: 3.125rem;\n  padding-bottom: 3.125rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
