import decode from 'jwt-decode';
import axios from 'axios';
import Geocode from 'react-geocode';

export const getLanLongFromAddress = (address) => {
  Geocode.geocode({ address }).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
    },
    (error) => {
      console.error(error);
    },
  );
};

export const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const apiCall = async (params) => {
  const token = localStorage.getItem('token');
  if (!token || isTokenExpired(token)) {
    localStorage.setItem('connected', false);
    //window.location.href = '/login';
    /*return axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/api/auth/refreshToken`, {
        token,
      })
      .then(async ({ data }) => {
        const response = data;
        localStorage.setItem('connected', true);
        localStorage.setItem('token', false);
        const newExtendedParams = {
          ...params,
          url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
          headers: {
            ...params.headers,
            Authorization: `Bearer ${response.token}`,
          },
        };
        return newExtendedParams;
      })
      .then((newExtendedParams) => axios(newExtendedParams))
      .catch((apiError) => {
        if (apiError.response && apiError.response.status === 503) {
          localStorage.setItem('connected', false);
          //document.location.reload();
        }
        return Promise.reject(apiError);
      });*/
  }
  const extendedParams = {
    ...params,
    url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
    headers: {
      ...params.headers,
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(extendedParams).catch((apiError) => {
    if (apiError.response && apiError.response.status === 503) {
      localStorage.setItem('connected', false);
      //document.location.reload();
    }
    return Promise.reject(apiError);
  });
};

export const hasRole = (roles, roleUser) => {
  if (roles && roles.length > 0) {
    const userRoleCodes = roles.map((role) => {
      if (role.code === roleUser) return true;
      else return false;
    });
    if (userRoleCodes[0]) return true;
    return false;
  }
  return false;
};

export const formatAmount = (amount) => {
  return new Intl.NumberFormat('en-IN', {}).format(amount);
};

export const minutesToHours = (num) => {
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return rhours + 'h' + rminutes + 'mn';
};

export const roles = {
  ADM: 'admin',
  PDS: 'pds',
  CBT: 'contributor',
};

export const formatDnd = (lines) => {
  let lineColumns = {};
  const columns = [];
  lines.length > 0 &&
    lines.map((line, index) => {
      lineColumns = {
        ...lineColumns,
        [line.id]: {
          ...columns,
        },
      };
    });
  return lineColumns;
};
